import React, { useState } from "react";
import { MainLayout } from "../layouts/MainLayout";
import { config } from "../helpers/production";
import backdrop from "../static/slider_images/1.jpg";

// markup
const Contact = () => {
  const initForm = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const email_body = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "x-rapidapi-key": config["x-rapidapi-key"],
      "x-rapidapi-host": config["x-rapidapi-key"],
    },
    body: {
      personalizations: [
        {
          to: [
            {
              email: config["owner-email"],
            },
          ],
          subject: "",
        },
      ],
      from: {
        email: "",
      },
      content: [
        {
          type: "text/plain",
          value: "",
        },
      ],
    },
  };

  const [form, setForm] = useState(initForm);
  const changeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const _form = { ...form, [name]: value };
    setForm(_form);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    email_body.body.personalizations[0].subject = form.subject;
    email_body.body.from.email = form.email;
    email_body.body.content[0].value = form.message;
    fetch(config["post-url"], email_body).then((res) => console.log(res));
    // .then(response => console.log(response));
  };

  return (
    <MainLayout>
      <section className="section contact-page">
        <div className="backdrop">
          <img src={backdrop} alt="" />
        </div>
        <main className="container">
          {/* <h2 className="title">Contact</h2> */}
          {/* FORM */}
          <div className="contact-form">
            <form
              name="contact-form"
              onChange={changeHandler}
              onSubmit={submitHandler}
            >
              <div className="field">
                <label htmlFor="name" className="label">
                  Name
                </label>
                <div className="control">
                  <input
                    id="name"
                    name="name"
                    className="input"
                    type="text"
                    placeholder="Text input"
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="email" className="label">
                  Email
                </label>
                <div className="control">
                  <input
                    id="email"
                    name="email"
                    className="input"
                    type="email"
                    placeholder="Email input"
                  />
                </div>
                {/* <p className="help is-danger">This email is invalid</p> */}
              </div>

              {/* <div className="field">
                <label htmlFor="subject" className="label">
                  Subject
                </label>
                <div className="control">
                  <input
                    id="subject"
                    name="subject"
                    className="input"
                    type="text"
                  />
                </div>
              </div> */}

              <div className="field">
                <label htmlFor="message" className="label">
                  Message
                </label>
                <div className="control">
                  <textarea
                    id="message"
                    name="message"
                    className="textarea"
                    placeholder="Textarea"
                  ></textarea>
                </div>
              </div>

              <div className="field is-grouped">
                <div className="control">
                  <button type="submit" className="button send-email">
                    SEND
                  </button>
                </div>
                <div className="control">
                  <button type="button" className="button is-light">
                    Cancel
                  </button>
                </div>
              </div>
              <br />
              <section className="email-phone">
                <small>Or get in touch directly</small>
                <p>
                  <span >Phone</span>
                  <a href="tel:+33 9 80 95 81 80">+33 9 80 95 81 80</a>
                  <br />
                  <span >Email</span>
                  <a href="mailto:avt@avt.sarl">avt@avt.sarl</a>
                </p>
              </section>
            </form>
          </div>
        </main>
      </section>
    </MainLayout>
  );
};

export default Contact;
